import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";
import {Sitemap} from "../components/mainNav";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.Part background="white headline">
                    <Container.Row>
                        <div className="col-12">
                            <h1>Sitemap</h1>
                        </div>
                    </Container.Row>
                </Container.Part>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-12">
                            <div className="sitemap">
                                <Sitemap/>
                            </div>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
